$breakpoint-480px: 480px;
$breakpoint-640px: 640px;
$breakpoint-768px: 768px;
$breakpoint-960px: 960px;
$breakpoint-1024px: 1025px;
$breakpoint-1200px: 1200px;

$global-left-padding: 6%;

//Colors
$theme-blue: #0d54ff;
$theme-gray: #828282;
$theme-red: #f96449;
$theme-border: #a0a0a0;
$theme-shade-100: #050505;
$theme-bg-footer: #e3e3e3;
