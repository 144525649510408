.input-text-search-wrapper {
  form {
    border-radius: 8px;
    overflow: hidden;
    height: 52px;
  }

  .search-input {
    width: 82%;
    height: 15px;
    padding: 15px;
    background: #fff;
    border-radius: 3px;
    border: 0;
  }

  .search-form {
    display: flex;
    align-items: center;
    border: 1px solid #d8d8d8;
  }
  .search-icon-wrapper {
    cursor: pointer;
    height: 52px;
    border-radius: 0px 3px 3px 0px;
    display: inline-block;
    background: $theme-blue;
    width: 23%;
    text-align: center;
    svg {
      position: relative;
      top: 10px;
      color: #fff;
    }
  }
}
