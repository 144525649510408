.article-wrapper {
  @media (min-width: $breakpoint-1024px) {
    display: flex;
    justify-content: left;
    gap: 48px;
  }

  .article-image {
    img {
      max-width: 100%;
      @media (min-width: $breakpoint-768px) {
        max-width: 350px;
      }
    }
  }

  .article-content-wrapper {
    @media (min-width: $breakpoint-1024px) {
    }

    .article-title {
      margin: 0;
      color: $theme-blue;

      & > a {
        font-size: 32px;
        line-height: 1.05;
      }

      @media (min-width: $breakpoint-768px) {
      }
      @media (min-width: $breakpoint-1024px) {
      }
    }

    .published-info > p {
      margin: 8px 0;
      color: $theme-gray;
    }

    img,
    video,
    object,
    iframe,
    embed {
      max-width: 100%;
    }
  }

  .btn-wrapper {
    margin-top: 30px;
    svg {
      margin-left: 10px;
    }
  }

  .article-content {
    > div {
      font-size: 17px;
      line-height: 1.5;
      font-weight: 300;
    }
    > table {
      table-layout: fixed;
      width: 100%;
    }

    p {
      color: black !important;
    }
  }
}
