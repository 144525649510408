.filter-container {
  padding: 20px;
  margin-bottom: 30px;
  border: 1px solid $theme-border;
  border-radius: 8px;
  @media (min-width: $breakpoint-1024px) {
    padding: 40px 30px;
  }

  .filter-wrapper {
    .loading-img {
      display: block;
      width: 50px;
      margin: 0 auto;
    }
  }

  h3.filter-title {
    font-size: 24px;
    line-height: 1.1;
  }
}
