.needMoreInfo-wrapper {
  padding: 20px;
  margin-bottom: 30px;
  border: 1px solid $theme-border;
  border-radius: 8px;
  @media (min-width: $breakpoint-1024px) {
    padding: 40px 30px;
  }

  &.collapsed {
    .needMoreInfo-content {
      max-height: 0;
      opacity: 0;
      transition: all 0.3s ease-out;
      height: auto;
      @media (min-width: $breakpoint-768px) {
        max-height: 1000px;
        opacity: 1;
      }
    }

    .needMoreInfo-icon-chevron {
      svg {
        transform: rotate(-270deg);
      }
    }

    .block {
      @media (min-width: $breakpoint-768px) {
        margin-top: 20px;
        p {
          margin-bottom: 4px;
        }
      }
    }
  }

  &.expanded {
    .needMoreInfo-content {
      overflow: hidden;
      transition: all 0.5s ease-out;
      height: auto;
      max-height: 1000px;
      opacity: 1;
    }

    .needMoreInfo-icon-chevron {
      svg {
        transform: rotate(-90deg);
      }
    }

    .block {
      margin-top: 20px;

      p {
        margin-bottom: 5px;
        line-height: 1.2;
      }
    }
  }

  .needMoreInfo-icon-chevron {
    svg {
      fill: $theme-blue;
    }
  }

  .cta-button {
    margin-top: 10px;

    svg {
      margin-left: 10px;
    }
  }

  .needMoreInfo-header {
    .title {
      position: relative;
      margin: 0;
    }

    .needMoreInfo-icon-chevron {
      display: inline-block;
      position: absolute;
      right: 20px;
      @media (min-width: $breakpoint-768px) {
        display: none;
      }
    }
  }
}
