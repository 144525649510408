.article-filter-container-wrapper {
  margin-top: -40px;
  @media (min-width: $breakpoint-768px) {
    margin-top: 40px;
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    gap: 48px;
  }

  .filter-container-wrapper {
    min-width: 300px;
    width: 100%;
    @media (min-width: $breakpoint-768px) {
      width: 350px;
    }
    @media (min-width: $breakpoint-1024px) {
      width: 29%;
    }
  }
  .articles-container-wrapper {
    @media (min-width: $breakpoint-768px) {
      width: 69%;
    }
  }
}
