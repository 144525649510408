.article-collection-wrapper {
  .article-divider {
    width: 100%;
    height: 2px;
    background-color: $theme-blue;
    margin: 30px 0;
  }

  .article-anchor {
    display: block;
  }
}
