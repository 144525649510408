.cta-button {
  padding: 10px 15px;
  border: 0;
  -webkit-appearance: none;
  display: inline-flex;
  align-items: center;
  text-decoration: none;
  letter-spacing: 0.06em;
  outline: 0;
  cursor: pointer;
  position: relative;
  justify-content: space-between;
  min-width: 130px;
  border-radius: 9999px;
  &._active {
    background-color: $theme-blue;
    color: #fff;
  }
  &._inactive {
    background-color: #fff;
    color: $theme-blue;
    border: 1px solid $theme-blue;
  }
}
