.hero-section-wrapper {
  padding: 32px 20px;
  background-color: $theme-blue;
  min-height: 440px;
  margin-bottom: 40px;

  @media (min-width: $breakpoint-768px) {
    padding: 32px 64px;
    display: flex;
    flex-direction: row-reverse;
    margin-bottom: 0;
  }

  .hero-section-inside {
    border-radius: 8px;
    background: #fff;
    overflow: hidden;
    display: flex;
    flex-direction: column-reverse;
    justify-content: space-between;

    @media (min-width: $breakpoint-768px) {
      flex-direction: row;
      gap: 70px;
    }
  }

  .hero-content-wrapper {
    margin: 30px 0 15px 56px;
    @media (min-width: $breakpoint-768px) {
      margin: 68px 0 15px 56px;
    }

    .hero-title > h1 {
      font-size: 44px;
      margin-top: 0;
      margin-bottom: 16px;
      color: $theme-red;
    }
  }

  .hero-image {
    align-self: end;
    height: 344px;
    @media (min-width: $breakpoint-768px) {
      height: 250px;
    }
    @media (min-width: $breakpoint-1024px) {
      height: 344px;
    }
  }
}
