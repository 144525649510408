@import "~normalize.css";

@font-face {
  font-family: "Telstra Akkurat";
  src: url("../fonts/telstra-akkurat-regular.woff");
}

@font-face {
  font-family: "Telstra Display";
  src: url("../fonts/TelstraDisplay-Medium.woff");
}

@font-face {
  font-family: "Telstra Text Light";
  src: url("../fonts/TelstraText-Light.woff");
}

@font-face {
  font-family: "Telstra Text Medium";
  src: url("../fonts/TelstraText-Medium.woff");
}

@font-face {
  font-family: "Telstra Text Bold";
  src: url("../fonts/TelstraText-Bold.woff");
}

body {
  margin: 0;
  padding: 0;
  font-family: "Telstra Text Light", Arial, sans-serif;
}

body {
  margin: 0;
  padding: 0;
}

* {
  box-sizing: border-box;
}

h1,
h2,
h3,
h4,
h5 {
  font-family: "Telstra Display", Arial, sans-serif;
  font-weight: 500;
  color: $theme-shade-100;
}

h3 {
  font-size: 24px;
  line-height: 1.1;
  letter-spacing: 0.2px;
}

p {
  margin-top: 0;
  font-size: 17px;
  line-height: 1.5;
  font-weight: 300;
}

a {
  text-decoration: none;
  cursor: pointer;
  color: inherit;
}

.theme-blue-copy {
  color: $theme-blue;
}

.section-bg {
  height: 5px;
  background: $theme-blue;
}

.bold-copy {
  font-family: "Telstra Text Bold";
  font-weight: 700;
}

.m-0 {
  margin: 0;
}
