.header-wrapper {
  .header-logo-holder {
    padding: 20px;
    @media (min-width: $breakpoint-768px) {
      padding-left: $global-left-padding;
    }
    img {
      width: 160px;
    }
  }
}
