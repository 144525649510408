.footer-wrapper {
  background-color: $theme-bg-footer;
  margin-top: 50px;

  .footer-links-container {
    padding: 20px;
    @media (min-width: $breakpoint-768px) {
      margin: 0 $global-left-padding;
      padding: 0 0 20px 0;
    }
  }

  .footer-links-wrapper {
    padding: 0;
    display: flex;
    flex-direction: column;

    @media (min-width: 400px) {
      flex-direction: row;
      white-space: nowrap;
    }

    .footer-link {
      list-style: none;
      margin-right: 10px;
      @media (min-width: $breakpoint-768px) {
        margin-right: 10px;
      }
      &:last-child {
        .footer-link-anchor:after {
          display: none;
        }
      }

      .footer-link-anchor:after {
        display: none;
        @media (min-width: 400px) {
          display: inline;
        }
      }
    }

    .footer-link-anchor {
      color: #000;
      &:after {
        content: "|";
        display: inline-block;
        margin-left: 10px;
        @media (min-width: $breakpoint-768px) {
          margin-left: 10px;
        }
      }
    }
  }
  .footer-copy-title {
    font-family: Arial, sans-serif;
    font-weight: bold;
    margin-bottom: 2px;
    font-size: 12px;
    @media (min-width: $breakpoint-768px) {
      font-size: 14px;
    }
  }
  .footer-copy-wrapper {
    font-family: Arial, sans-serif;
    h5 {
      display: inline;
    }
  }
  .footer-copy-content {
    display: inline;
    margin-left: 4px;
    font-family: Arial, sans-serif;
    font-size: 12px;
    @media (min-width: $breakpoint-768px) {
      font-size: 14px;
    }
  }
}
