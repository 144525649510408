.input-select-wrapper {
  position: relative;
  select {
    width: 100%;
    height: 52px;
    padding: 16px;
    background: #fff;
    -webkit-appearance: none;
    -moz-appearance: none;
    border-color: $theme-border;
    border-radius: 8px;
    font-family: "Telstra Text Medium";
    font-size: 17px;
    font-weight: 500;
  }

  .icon-dropdown {
    position: absolute;
    right: 20px;
    top: 18px;
  }
}
